// Most of this list can be fetched via /api/v1/bookingflow-server/cart/products
export const PRODUCT_IDS = {
  // Day visit
  BLWinterCard: 'B-071', // 'BL Winter Card - Individual Admission'
  BLSummerCard: 'B-138', // 'BL Summer Card - Individual Admission'
  BLAnnualCard: 'B-136', // 'BL Annual Card - Individual Admission'
  BLWinterCardFamily: 'B-070', // 'BL Winter Card - Family Admission'
  BLSummerCardFamily: 'B-139', // 'BL Summer Card - Family Admission'
  BLAnnualCardFamily: 'B-137', // 'BL Annual Card - Family Admission'
  SpaComfort: 'G-BL-410A', // 'Comfort Blue Lagoon'
  SpaPremium: 'G-BL-420A', // 'Premium Blue Lagoon'
  SpaSignature: 'G-BL-430', // 'Signature Blue Lagoon'
  SpaRetreat: 'T-100', // 'The Retreat SPA'
  SpaChild: 'G-BL-404', // "Children's BL SPA Access"

  // Massage and float
  Massage30: 'B-217', // '30 min-In Water Relaxing Massage'
  Massage60: 'B-218', // '60 min-In Water Relaxing Massage'
  Massage120: 'B-229', // 'Signature Treatment: 120 min of Wonder'
  MassageFloatingOne: 'B-230', // 'Floating for one'
  MassageFloatingTwo: 'B-230A', // 'Floating for two'
  MassageFloatingGroup: 'B-230B', // 'Floating - Samflot'
  MassageFloatingMidnight: 'B-230C', // 'Floating - Midnight'

  // Indoor Treatments
  IndoorMassage30: 'T-200', // 'Retreat Massage (30 min)'
  IndoorMassage60: 'T-201', // 'Retreat Massage (60 min)'
  IndoorMassage90: 'T-202', // 'Retreat Massage (90 min)'
  IndoorHotStoneMassage60: 'T-204', // 'Hot Stone Massage (60 min)'
  IndoorHotStoneMassage90: 'T-205', // 'Hot Stone Massage (90 min)'
  IndoorFacial: 'T-299', // 'Facial (45 min)'
  IndoorMiracleFacial: 'T-300', // 'Miracle Facial (60 min)'
  IndoorDeluxeFacial: 'T-301', // 'Deluxe Facial (90 min)'
  IndoorClassicManicure: 'T-314', // 'Classic Manicure (60 min)'
  IndoorDeluxeManicure: 'T-316', // 'Deluxe Manicure (90 min)'
  IndoorClassicPedicure: 'T-317', // 'Classic Pedicure (60 min)'
  IndoorDeluxePedicure: 'T-319', // 'Deluxe Pedicure (90 min)'

  // Private transfer valdemar (Silica Hotel)
  PrivateTransferArrivalSmall: 'VALDEMAR - 3', // Private transfer arrival 1-3 guests
  PrivateTransferArrivalLarge: 'VALDEMAR - 14', // Private transfer arrival 4-8 guests
  PrivateTransferDepartureSmall: 'VALDEMAR - 2', // Private transfer departure 1-3 guests
  PrivateTransferDepartureLarge: 'VALDEMAR - 13', // Private transfer departure 4-8 guests

  // Private transfer servio (The Retreat)
  // Departure
  PrivateTransferDepartureServioSmall: 'SERVIO - 2', // Transfer - Blue Lagoon - Kef Airport - Luxury Sedan (1-2 pax)
  PrivateTransferDepartureServioMedium: 'SERVIO - 2A', // Transfer - Blue Lagoon - Kef Airport - Business Minivan (1-4 pax)
  PrivateTransferDepartureServioLarge: 'SERVIO - 2B', // Transfer - Blue Lagoon - Kef Airport - Sprinter (1-9 pax)
  // Arrival
  PrivateTransferArrivalServioSmall: 'SERVIO - 3', // Transfer - Kef Airport - Blue Lagoon - Luxury Sedan (1-2 pax)
  PrivateTransferArrivalServioMedium: 'SERVIO - 3A', // Transfer - Kef Airport - Blue Lagoon - Business Minivan (1-4 pax)
  PrivateTransferArrivalServioLarge: 'SERVIO - 3B', // Transfer - Kef Airport - Blue Lagoon - Sprinter (1-9 pax)

  // Other activities
  RestaurantLava: 'LAVA-RES', // 'LAVA Table Reservation'
  RestaurantMoss: 'MOSS-RES', // 'Moss Table Reservation'
  TransferTwoWay: 'R-105', // 'DBL Two way transfer'
  ReykjanesFromAboveTour: 'LUXURY-AVIATION', // Reykjanes from above tour
  SpaRestaurant: 'SPA-RES', // 'Spa Table Reservation'
  PrivateWineTasting: 'PRIVATE WINETASTING', // 'Private Wine Tasting'

  // Hotel rooms
  HotelSilicaLavaKing: 'SILICA-LAKI', // 'Lava Deluxe King'
  HotelSilicaLavaTwin: 'SILICA-LATW', // 'Lava Deluxe Twin'
  HotelSilicaMountainKing: 'SILICA-MOKI', // 'Mountain Deluxe King'
  HotelSilicaMountainTwin: 'SILICA-MOTW', // 'Mountain Deluxe Twin'
  HotelSilicaDeluxe: 'SILICA-SILI', // 'Silica hotel room SILI'
  HotelSilicaAll: 'SILICA-ALL', // 'All Silica hotel room types'
  HotelRetreatLagoonJuniorKing: 'RETREAT-LAGO', // 'Lagoon View Junior King'
  HotelRetreatLagoonSuite: 'RETREAT-LASU', // 'Lagoon Suite'
  HotelRetreatLavaJuniorTwin: 'RETREAT-LATW', // 'Lava View Junior Twin'
  HotelRetreatLavaJuniorKing: 'RETREAT-LAVA', // 'Lava View Junior King'
  HotelRetreatMossJuniorKing: 'RETREAT-MOSS', // 'Moss Junior suite King'
  HotelRetreatMossSuite: 'RETREAT-MOSU', // 'Moss suite King'
  HotelRetreatMossJuniorTwin: 'RETREAT-MOTW', // 'Moss Junior suite Twin'
  HotelRetreatAll: 'RETREAT-ALL', // 'All Reatreat hotel room types'

  // Room addons/products
  SilicaMudMask100ml: 'BL1B01F', // 'Silica Mud Mask 100ml'
  SilicaMudMask75ml: 'BL1B01F1', // 'Silica Mud Mask 75ml'
  SilicaMudMask30ml: 'BL1B02M3', // 'Silica Mud Mask 30ml'
  Champagne: 'V-OLG-66502', // Moet & Chandon
  ChocolatesAndStrawberries: 'V-MOK-3004', // Chocolates and Strawberries
  SparklingWine: 'V-OLG-60442', // Sparkling wine
  EdibleFlowerRoses: 'V-BAN-13891', // 'Mash Edible flower roses'

  // Surprise activities/room-addons
  FlowersBlomaval: 'BLÓMAVAL',
  FlowersBlomakot: 'BLÓMAKOT',
  Balloons: 'MIMOSA',
  Mimosa: 'H-TA22',
  FlowersGifts: 'H-TA16',

  // These are "un-bookable", and therefore not listed in a product list
  SilicaGuest: 'SILICA GUEST', // Silica hotel guest pass
  Guest: 'GUEST', // The Retreat guest pass
  HighlandGuest: 'HOTEL-GUEST', // Highland base hotel guest pass
  // Bath Day visit in HB
  AdultAdmission: 'A-200', // Bath Admission - Adult
  TeenAdmission: 'A-201', // Bath Admission - Teenager
  ChildAdmission: 'A-202', // Bath Admission - Child
} as const

type ProductIdKey = keyof typeof PRODUCT_IDS

export type ProductId = typeof PRODUCT_IDS[ProductIdKey]

export const bluelagoonDayVisitProductIds = [
  PRODUCT_IDS.SpaComfort,
  PRODUCT_IDS.SpaPremium,
  PRODUCT_IDS.SpaRetreat,
  PRODUCT_IDS.SpaSignature,
  PRODUCT_IDS.SpaChild,
  PRODUCT_IDS.BLWinterCard,
  PRODUCT_IDS.BLSummerCard,
  PRODUCT_IDS.BLAnnualCard,
  PRODUCT_IDS.BLWinterCardFamily,
  PRODUCT_IDS.BLSummerCardFamily,
  PRODUCT_IDS.BLAnnualCardFamily,
]

export const massageProductIds = [
  PRODUCT_IDS.Massage30,
  PRODUCT_IDS.Massage60,
  PRODUCT_IDS.Massage120,
]

export type MassageProductId = typeof massageProductIds[number]

export const floatProductIds = [
  PRODUCT_IDS.MassageFloatingOne,
  PRODUCT_IDS.MassageFloatingTwo,
  PRODUCT_IDS.MassageFloatingGroup,
  PRODUCT_IDS.MassageFloatingMidnight,
]

export type FloatProductId = typeof floatProductIds[number]

export const massageAndFloatProductIds = [
  ...floatProductIds,
  ...massageProductIds,
]

export const privateTransferValdemarIds = [
  PRODUCT_IDS.PrivateTransferArrivalLarge,
  PRODUCT_IDS.PrivateTransferArrivalSmall,
  PRODUCT_IDS.PrivateTransferDepartureLarge,
  PRODUCT_IDS.PrivateTransferDepartureSmall,
]

export type PrivateTransferValdemarProductId =
  typeof privateTransferValdemarIds[number]

export const privateTransferServioIds = [
  PRODUCT_IDS.PrivateTransferDepartureServioSmall,
  PRODUCT_IDS.PrivateTransferDepartureServioMedium,
  PRODUCT_IDS.PrivateTransferDepartureServioLarge,
  PRODUCT_IDS.PrivateTransferArrivalServioSmall,
  PRODUCT_IDS.PrivateTransferArrivalServioMedium,
  PRODUCT_IDS.PrivateTransferArrivalServioLarge,
]

export type PrivateTransferServioProductId =
  typeof privateTransferServioIds[number]

// List of all private transfer arrival products
export const privateTransferArrivalIds = [
  PRODUCT_IDS.PrivateTransferArrivalLarge,
  PRODUCT_IDS.PrivateTransferArrivalSmall,
  PRODUCT_IDS.PrivateTransferArrivalServioSmall,
  PRODUCT_IDS.PrivateTransferArrivalServioMedium,
  PRODUCT_IDS.PrivateTransferArrivalServioLarge,
]

export type PrivateTransferArrivalProductIds =
  typeof privateTransferArrivalIds[number]

// Cart items
export const CartItemActivities = ['Massage', 'Restaurant'] as const

export const membershipProductIds = [
  PRODUCT_IDS.BLWinterCard,
  PRODUCT_IDS.BLWinterCardFamily,
  PRODUCT_IDS.BLAnnualCard,
  PRODUCT_IDS.BLAnnualCardFamily,
  PRODUCT_IDS.BLSummerCard,
  PRODUCT_IDS.BLSummerCardFamily,
] as const

export type MembershipProductIds = typeof membershipProductIds[number]

export const spaProductIdPackageMap = {
  [PRODUCT_IDS.SpaComfort]: 'comfort',
  [PRODUCT_IDS.SpaPremium]: 'premium',
  [PRODUCT_IDS.SpaSignature]: 'signature',
  [PRODUCT_IDS.SpaRetreat]: 'retreatspa',
  [PRODUCT_IDS.BLWinterCard]: 'wintercard',
  [PRODUCT_IDS.BLWinterCardFamily]: 'wintercard',
  [PRODUCT_IDS.BLSummerCard]: 'summercard',
  [PRODUCT_IDS.BLSummerCardFamily]: 'summercard',
}

export const isAnnualCard = (packageProductNo: string) => {
  // We can safely cast here since if the key is not found, it will be undefined and therefore not equal to 'wintercard' or 'summercard'
  const typedPackageProductNo =
    packageProductNo as keyof typeof spaProductIdPackageMap
  return (
    spaProductIdPackageMap[typedPackageProductNo] === 'wintercard' ||
    spaProductIdPackageMap[typedPackageProductNo] === 'summercard'
  )
}

// Until we can get this typed from BL API
export const ACTIVITY_STATUS = {
  Draft: 'DRAFT',
}
export const ACTIVITY_SUBSTATUS = {
  WaitingOnApproval: 'WAITING ON APPROVAL',
  // WaitingOnLookup is manually set in client when bl-api does not
  // return all required data because f.x Bókun is still processing the bus activity
  WaitingOnLookup: 'WAITING ON LOOKUP',
}
export const productIdToAdmissionType = {
  [PRODUCT_IDS.SpaComfort]: 'comfort',
  [PRODUCT_IDS.SpaPremium]: 'premium',
  [PRODUCT_IDS.SpaRetreat]: 'retreat',
  [PRODUCT_IDS.SpaSignature]: 'signature',
  [PRODUCT_IDS.BLSummerCard]: 'subscription',
  [PRODUCT_IDS.BLSummerCardFamily]: 'subscription',
  [PRODUCT_IDS.BLWinterCard]: 'subscription',
  [PRODUCT_IDS.BLWinterCardFamily]: 'subscription',
}

// Most of this list can be fetched via /api/v1/bookingflow-server/cart/products
export const HB_PRODUCT_IDS = {
  // Day visit
  AdultAdmission: 'A-200', // Bath Admission - Adult
  TeenAdmission: 'A-201', // Bath Admission - Teenager
  ChildAdmission: 'A-202', // Bath Admission - Child
  HighlandBaseRestaurant: 'KER-RES', // 'Highland Base Restaurant
  TransferRvkHb: 'TRANSFER-RVK-HB', // 'Transfer Reykjavik → Highland Base'
  TransferHbRvk: 'TRANSFER-HB-RVK', // 'Transfer Highland Base → Reykjavik'
  TransferSkjolHb: 'TRANSFER-SKJOL-HB', // 'Transfer Skjól → Highland Base'
  TransferHbSkjol: 'TRANSFER-HB-SKJOL', // 'Transfer Highland Base → Skjól'
  TransferTwoWay: 'R-1000', // 'DBL Two way transfer for bus transfer'
} as const

export const highlandbaseDayVisitProductIds = [
  HB_PRODUCT_IDS.AdultAdmission,
  HB_PRODUCT_IDS.TeenAdmission,
  HB_PRODUCT_IDS.ChildAdmission,
]

export const highlandBaseTransferProductIds = [
  HB_PRODUCT_IDS.TransferRvkHb,
  HB_PRODUCT_IDS.TransferHbRvk,
  HB_PRODUCT_IDS.TransferSkjolHb,
  HB_PRODUCT_IDS.TransferHbSkjol,
]

// Activities that are editable through myBooking
export const editableActivities = [
  PRODUCT_IDS.TransferTwoWay,
  PRODUCT_IDS.RestaurantLava,
  PRODUCT_IDS.SpaRestaurant,
  HB_PRODUCT_IDS.HighlandBaseRestaurant,
  ...massageAndFloatProductIds,
]

export type EditableActivitiesProductId = typeof editableActivities[number]
export type HighlandBaseTransferProductIds =
  typeof highlandBaseTransferProductIds[number]

type HighlandIdKey = keyof typeof HB_PRODUCT_IDS

export type HighlandProductId = typeof HB_PRODUCT_IDS[HighlandIdKey]
// This combination of productIds is used by My Booking and should not be used to fetch availability in the booking flows
export const dayVisitProductIds = [
  ...bluelagoonDayVisitProductIds,
  ...highlandbaseDayVisitProductIds,
]

export type DayVisitProductId = typeof dayVisitProductIds[number]
